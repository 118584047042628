import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Card from "../components/Card"
import { smallContainer } from "../components/container"
import { colors } from "../theme"

// Hero Section
const hero = css`
  position: relative;
`
const StyledCard = styled(Card)`
  position: relative;
  z-index: 10;
  margin: 50px auto;
`

const heroHeadline = css`
  font-size: 36px;
  position: relative;
  top: -25px;
  margin-bottom: 0;
`

const heroText = css`
  font-size: 18px;
  line-height: 1.3;
  color: ${colors.roseDark};
  max-width: 600px;
  margin: 20px 0;
`

const Registry = () => (
  <Layout>
    <SEO title="Registry" />
    <Header />
    <div css={[smallContainer, hero]}>
      <StyledCard>
        <h1 css={heroHeadline}>Questions</h1>
        <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
        <iframe
          class="airtable-embed airtable-dynamic-height"
          src="https://airtable.com/embed/shrGaN882HfbEjJyW?backgroundColor=greenLight"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="827"
        ></iframe>
      </StyledCard>
    </div>
  </Layout>
)

export default Registry
